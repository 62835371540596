import { NightGoalGraph } from '@component/charts/night-goal-graph.component';
import { getDiariesWithEmptyValues } from '@component/charts/utils';
import { GatsbyButton } from '@components/atm.button/button.component';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { getSleepDiaryAnalysis, SleepDiaryParams } from '@global/utils/domain/diary-analysis';
import { roundGradeValue } from '@global/utils/domain/entities';
import { getTitleForNightGoal, NightGoals } from '@global/utils/domain/goals';
import { getStandardDeviation } from '@global/utils/number/statistics';
import { Query_Root, Sleep_Diary } from '@global/utils/remote-graphql-types';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { SleepDiaryQuery } from '@lp-root/src/data/graphql/hasura/sleep-diary.query.hasura';
import { buildChatfuelBroadcastRequest } from '@lp-root/src/data/request-utils/redirect.request';
import { PageProps } from '@lp-root/src/utils/local-types';
import { BodySecondary, Col, FaIcon, Grid, H1, H2, Row, Separator } from '@web/atomic';
import { OpinionatedColorStyled } from '@web/atomic/atm.opinionated-color/opinionated-color.styled';
import { StickButtonWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { ShimmerInput } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { Table, TD, TH, THead, TR } from '@web/atomic/legacy/mol.table';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useClientRect } from '@web/atomic/obj.custom-hooks/client-rect.hook';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import { UserIdDataSource } from '@web/data/user-id.datasource';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import React, { useMemo } from 'react';

const GraficoObjetivoNoitePage: React.FunctionComponent<PageProps> = (props) => {
  const { data, error, loading } = useQueryCustom(SleepDiaryQuery);
  const summary = useNightSummary(data);

  const [rect, titleRef] = useClientRect();

  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const [post, { loading: closeLoading }] = usePostUsingMessenger<ChatfuelBroadcastRequest>({
    onSuccess: close,
    url: PostUrl.RedirectUser,
  });
  const handleClose = async () => {
    console.log('DIARY_DEBUG: grafico-objetivo-noite.tsx ~ line 41 ~ handleClose ~ userId');
    const userId = await UserIdDataSource.getId();
    const weekImprove = getImproveForGoal(summary.first, summary.current, userNightGoal);
    const globalImprove = getImproveForGoal(summary.first, summary.everything, userNightGoal);
    const request = buildChatfuelBroadcastRequest(userId, 'goal_review_night_b2', {
      'global-night_start_avg': summary.first,
      'global-night_week_avg': summary.current,
      'global-night_global_avg': summary.everything,
      'global-night_improve_week': weekImprove,
      'global-night_improve_global': globalImprove,
      'global-night_improve': weekImprove > 0 || globalImprove > 0,
    });
    return post(request);
  };
  const userNightGoal = data && data.user[0].night_goal;
  const diaries = getDiariesWithEmptyValues(data && data.sleep_diary);

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Objetivo da noite',
          description: 'Veja como você está evoluindo no seu objetivo da noite',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12}>
            <H1 ref={titleRef}>Objetivo da noite</H1>
          </Col>
        </Row>
        <LoadingState loading={loading} error={!!error} data={!!diaries}>
          {/* TODO: UI - ajustar loading */}
          <LoadingState.Shimmer>
            <>
              {Array(3)
                .fill(1)
                .map((_item, index) => (
                  <Row mt mb key={index}>
                    <Col xs={12} sm={12} md={7} lg={7}>
                      <ShimmerInput />
                      <Separator />
                    </Col>
                  </Row>
                ))}
            </>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <Placeholder
              icon={<FaIcon.FlashAlert size="9x" />}
              title={'Erro ao pegar seus dados'}
              description="Tente novamente mais tarde"
            />
          </LoadingState.Error>
          <LoadingState.NoData>
            <Placeholder
              icon={<FaIcon.Table size="9x" />}
              title={'Seu diário está em branco'}
              description={`Quando você preencher seu diário, os seus dados podem ser vistos nessa tela, ok? Preencha 1 vez e veja como fica aqui!`}
            >
              <GatsbyButton onClick={close}> Combinado! </GatsbyButton>
            </Placeholder>
          </LoadingState.NoData>

          {summary && (
            <Row mt mb>
              <Col xs={12}>
                <H2> {summary.title}</H2>
                <NightGoalDescription userNightGoal={userNightGoal} />
                <Table>
                  <THead>
                    <TR>
                      <TH textAlign="left">No início</TH>
                      <TH textAlign="left">Atualmente</TH>
                      <TH textAlign="left">Em todo programa</TH>
                    </TR>
                  </THead>
                  <NightGoalTableRow userNightGoal={userNightGoal} {...(summary as SleepDataSummary)} />
                </Table>
              </Col>
            </Row>
          )}

          {data && (
            <Row mt mb>
              <Col xs={12}>
                <H2>Todos dados</H2>
                <NightGoalGraph width={rect && rect.width} sleepDiaryData={diaries} user={data.user[0]} />
              </Col>
            </Row>
          )}
          {!loading && (
            <StickButtonWrapper>
              <GatsbyButton onClick={handleClose} expanded loading={closeLoading || waitCloseLoading}>
                Continuar
              </GatsbyButton>
            </StickButtonWrapper>
          )}
        </LoadingState>
      </Grid>
    </WebviewLayout>
  );
};

const getImproveForGoal = (first: number, current: number, goal: NightGoals) => {
  const improve = Math.round(((current - first) / first) * 100);
  switch (goal) {
    case NightGoals.Latency:
      return -improve;
    case NightGoals.Maintenance:
      return -improve;
    case NightGoals.Mood:
      return improve;
    case NightGoals.Reliability:
      return -improve;
    default:
      console.error(`ERROR: getImprove -> invalid goal: ${goal}`);
      return 0;
  }
};

export default GraficoObjetivoNoitePage;

export const query = graphql`
  query GraficoObjetivoNoitePage {
    site {
      ...SiteUrl
    }
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface INightGoalDescriptionProps {
  userNightGoal: NightGoals;
}

const NightGoalDescription: React.FunctionComponent<INightGoalDescriptionProps> = (props) => {
  if (!props.userNightGoal) {
    return null;
  }

  switch (props.userNightGoal) {
    case NightGoals.Latency:
      return (
        <BodySecondary
          role="tooltip"
          data-microtip-position="top"
          data-microtip-size="large"
          aria-label="Esse é o tempo que você demorar para pegar no sono a primeira vez."
        >
          Veja abaixo os valores médios do tempo para dormir (em minutos). <FaIcon.Question />:
        </BodySecondary>
      );
    case NightGoals.Maintenance:
      return (
        <BodySecondary
          role="tooltip"
          data-microtip-position="top"
          data-microtip-size="large"
          aria-label="Esse é o tempo que você passa sem dormir depois de ter pego no sono a primeira vez, ou seja, é o tempo que te atrapalha a dormir a noite inteira."
        >
          Veja abaixo os valores médios do tempo acordado (em minutos) durante a noite. <FaIcon.Question />:
        </BodySecondary>
      );
    case NightGoals.Mood:
      return (
        <BodySecondary
          role="tooltip"
          data-microtip-position="top"
          data-microtip-size="large"
          aria-label="Para o cálculo, considerou-se que: 🙂 = 100 (bom); 😐 = 50 (médio); 😞 = 0 (ruim)"
        >
          Veja abaixo os valores médios da sua disposição (de 0 a 100) <FaIcon.Question />:
        </BodySecondary>
      );
    case NightGoals.Reliability:
      return (
        <BodySecondary
          role="tooltip"
          data-microtip-position="top"
          data-microtip-size="large"
          aria-label="Sua a meta é ter uma eficiência do sono constante, pois isso significa que conseguirá ter mais confiança em quantas irá dormir toda noite."
        >
          Veja abaixo os valores médios de como a eficiência do seu sono variou. <FaIcon.Question />:
        </BodySecondary>
      );
    default:
      throw new Error(`NightGoalDescription: invalid userNightGoal: ${props.userNightGoal}`);
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ISleepRestrictionCycleTableRowProps extends SleepDataSummary {
  userNightGoal: NightGoals;
}

const NightGoalTableRow: React.FunctionComponent<ISleepRestrictionCycleTableRowProps> = (props) => {
  if (!props.first && !props.current && !props.everything) {
    return null;
  }

  return (
    <TR>
      <TD textAlign="left">{props.first}</TD>
      <TD textAlign="left">
        <OpinionatedColorStyled good={isNumberGood(props.first, props.current, props.userNightGoal)}>
          {props.current}
        </OpinionatedColorStyled>
      </TD>
      <TD textAlign="left">
        <OpinionatedColorStyled good={isNumberGood(props.first, props.everything, props.userNightGoal)}>
          {props.everything}
        </OpinionatedColorStyled>
      </TD>
    </TR>
  );
};

const isNumberGood = (first: number, value: number, goal: NightGoals) => {
  switch (goal) {
    case NightGoals.Latency:
      return value < first;
    case NightGoals.Maintenance:
      return value < first;
    case NightGoals.Mood:
      return value > first;
    case NightGoals.Reliability:
      return value < first;
    default:
      console.error(`ERROR: isNumberGood -> invalid goal: ${goal}`);
      return '';
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface SleepDataSummary {
  title: string;
  first: number;
  current: number;
  everything: number;
}

/**
 * Get user night goal averages
 * @param data
 */
const useNightSummary = (data: Query_Root) => {
  return useMemo(() => {
    if (!data || !data.sleep_diary) {
      return {} as SleepDataSummary;
    }

    const allData = data.sleep_diary;

    const weekDaysCount = 7;
    const currentWeekData = data.sleep_diary.slice(data.sleep_diary.length - weekDaysCount);
    const firstWeekData = data.sleep_diary.slice(0, weekDaysCount);

    const userNightGoal: NightGoals = data.user[0].night_goal;

    const summary: Partial<SleepDataSummary> = {
      first: getValueForNightGoal(userNightGoal, firstWeekData),
      current: getValueForNightGoal(userNightGoal, currentWeekData),
      everything: getValueForNightGoal(userNightGoal, allData),
    };

    summary.title = getTitleForNightGoal(userNightGoal);

    return summary;
  }, [data]);
};

// TODO: REFACTOR (SRP)
function getValueForNightGoal(userNightGoal: NightGoals, items: Sleep_Diary[]) {
  if (userNightGoal === NightGoals.Mood) {
    return roundGradeValue(items.reduce((acc, curr) => acc + curr.grade, 0) / items.length);
  }

  const analysis = items.map((item) => {
    const sleepDiaryParams: SleepDiaryParams = {
      goBed: item.go_bed,
      goSleep: item.go_sleep,
      timeToSleep: item.time_to_sleep,
      wakeUpDuration: item.wake_up_duration,
      wakeUp: item.wake_up,
      getUp: item.get_up,
    };

    return getSleepDiaryAnalysis(sleepDiaryParams);
  });

  switch (userNightGoal) {
    case NightGoals.Latency:
      return Math.round(analysis.reduce((acc, curr) => acc + curr.totalTimeToSleep, 0) / items.length);
    case NightGoals.Maintenance:
      return Math.round(analysis.reduce((acc, curr) => acc + curr.awakeAtNight, 0) / items.length);
    case NightGoals.Reliability:
      return Math.round(getStandardDeviation(analysis.map((item) => item.eficiency)));
    default:
      console.error('ERROR: useSummary -> invalid userNightGoal', userNightGoal);
      return null;
  }
}
