import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString } from '@global/utils/date/parse-to-string';
import { getSleepDiaryAnalysis } from '@global/utils/domain/diary-analysis';
import { getEmojiForSleepDiaryGrade } from '@global/utils/domain/entities';
import { NightGoals } from '@global/utils/domain/goals';
import { AppThemeContext, BrandColor, GrayColor, LightColor } from '@web/atomic';
import React, { useContext } from 'react';
import { Brush, CartesianGrid, ComposedChart, Legend, Line, LineProps, Tooltip, XAxis, YAxis } from 'recharts';
import { getCycleReferenceLine } from './cycle-reference.components';
import { ChartWrapperStyled } from './graph.styled';
import { numberOfDaysToShowInitiallyOnGraph, SleepDiaryGraphProps } from './utils';

type INightGoalGraphProps = SleepDiaryGraphProps;

export const NightGoalGraph: React.FunctionComponent<INightGoalGraphProps> = (props) => {
  const { theme } = useContext(AppThemeContext);

  if (!props.sleepDiaryData) {
    return null;
  }
  const labelDate = 'data';
  const labelLatency = 'minutos para dormir';
  const labelMaintenance = 'minutos acordados a noite';
  const labelMood = 'disposição ao acordar';
  const labelReliability = 'eficiência do sono';

  const cData = props.sleepDiaryData.map((item) => {
    const analysis = getSleepDiaryAnalysis({
      goBed: item.go_bed,
      goSleep: item.go_sleep,
      timeToSleep: item.time_to_sleep,
      wakeUpDuration: item.wake_up_duration,
      wakeUp: item.wake_up,
      getUp: item.get_up,
    });

    const response = {};
    response[labelDate] = parseDateToShortString(parseToDate(item.date));
    response[labelLatency] = analysis.totalTimeToSleep;
    response[labelMaintenance] = analysis.awakeAtNight;
    response[labelMood] = item.grade;
    response[labelReliability] = analysis.eficiency;

    return response;
  });

  // BUSINESS-RULE: the first cycle actually starts one day before the first diary
  if (props.cycles && props.cycles[0]) {
    cData.unshift({ name: parseDateToShortString(props.cycles[0].startDate) });
  }

  const lineProps: Partial<LineProps> = {
    type: 'monotoneX',
    dot: true,
    fill: theme.name === 'dark' ? BrandColor.Yellow : LightColor.Primary,
    stroke: theme.name === 'dark' ? BrandColor.Yellow : LightColor.Primary,
  };

  const userNightGoal = props.user.night_goal;

  return (
    <>
      <ChartWrapperStyled>
        <ComposedChart
          data={cData}
          height={props.height || 200}
          width={props.width}
          // POG-ALERT: this margin is a hack to remove space before/after Y-Axis
          margin={{ top: 0, right: 35, bottom: 0, left: -35 }}
        >
          <Legend verticalAlign="top" width={props.width} wrapperStyle={{ left: 0, top: -30 }} />
          <CartesianGrid stroke={theme.name === 'dark' ? GrayColor.GrayDark : GrayColor.GrayXXXLight} />
          <XAxis
            stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
            dataKey={labelDate}
            label={{ position: 'insideBottomRight', offset: 0 }}
          />
          <YAxis
            stroke={theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray}
            ticks={userNightGoal === NightGoals.Mood ? [1, 2, 3] : undefined}
            tickFormatter={userNightGoal === NightGoals.Mood ? getEmojiForSleepDiaryGrade : undefined}
            label={{ value: '', className: 'rechart-left-label', angle: -90, position: 'top' }}
          />
          {userNightGoal !== NightGoals.Mood && <Tooltip />}
          {!props.hideBrush && cData.length > 7 && (
            <Brush dataKey="name" height={30} startIndex={cData.length - numberOfDaysToShowInitiallyOnGraph} />
          )}
          {userNightGoal === NightGoals.Latency && (
            <Line isAnimationActive={!props.removeAnimation} dataKey={labelLatency} {...lineProps} ref={null} />
          )}
          {userNightGoal === NightGoals.Maintenance && (
            <Line isAnimationActive={!props.removeAnimation} dataKey={labelMaintenance} {...lineProps} ref={null} />
          )}
          {userNightGoal === NightGoals.Mood && (
            <Line isAnimationActive={!props.removeAnimation} dataKey={labelMood} {...lineProps} ref={null} />
          )}
          {userNightGoal === NightGoals.Reliability && (
            <Line isAnimationActive={!props.removeAnimation} dataKey={labelReliability} {...lineProps} ref={null} />
          )}
          {getCycleReferenceLine(props)}
        </ComposedChart>
      </ChartWrapperStyled>
    </>
  );
};
